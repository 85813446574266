export interface StorageInterface {
    [key: string]: string
}

export const useCookieStorage = defineStore('cookieStorage', {
    state: () => ({
        storage: <StorageInterface>{},
    }),
    actions: {
        update(value: string, key: string) {
            const newRef = { [key]: value }
            this.storage = { ...this.storage, ...newRef }

            return this.storage[key]
        },
        remove(key: string): void {
            delete this.storage[key]
        },
        get(key: string): string {
            return this.storage[key]
        },
    },
})
