export default {
    load_error: 'Error loading data {0}',
    load_error_title: 'Error',
    load_error_text: 'An error occurred while loading the data. Please reload the page.',
    addresses: 'Addresses',
    order_history: 'Orders to 2025',
    order_history_smo: 'Orders from 2025',
    order_details: 'Order',
    order_details_smo: 'Order',
    carts: 'Shopping carts',
    shopping_lists: 'Shopping lists',
    condition_sheets: 'Condition sheets',
    additional_header_fields: 'Additional header fields',
    options: 'Options',
    show_prices: 'Show prices',
    shipping_address_desc:
        "The available recipient addresses can be added and managed via the <a href='https://portal.intersport.de/irj/portal?NavigationTarget=navurl://2b5bd9fcb411fca4bf49c1cc2dae3552' target='_blank'>Intersport Portal</a>.",
    categories: 'Categories',
    filters: 'Filter',
    sort_by: 'Sort by',
    products_found: 'Products found',
    about_us: 'About us',
    who_we_are: 'Who we are',
    quality_in_the_details: 'Quality in the details',
    customer_reviews: 'Customer Reviews',
    departments: 'Departments',
    women_fashion: 'Women fashion',
    men_fashion: 'Men fashion',
    kidswear: 'Kidswear',
    home: 'Home',
    help: 'Help',
    customer_service: 'Customer service',
    size_guide: 'Size guide',
    contact_us: 'Contact us',
    payment_and_delivery: 'Payment & Delivery',
    purchase_terms: 'Purchase terms',
    guarantee: 'Guarantee',
    read_reviews: 'Read reviews',
    additional_information: 'Additional Information',
    save_for_later: 'Save for later',
    add_to_compare: 'Add to compare',
    match_it_with: 'Match it with',
    share_your_look: 'Share your look',
    product_description: `The Karissa V-Neck Tee features a semi-fitted shape that's flattering for every figure. You can hit the gym with confidence while it hugs curves and hides common "problem" areas. Find stunning women's cocktail dresses and party dresses.`,
    brand: 'Brand',
    instruction1: 'Take care of me',
    instruction2: 'Just here for the care instructions?',
    instruction3: 'Yeah, we thought so',
    items: 'Items',
    view: 'View',
    'show on page': 'Show on page',
    done: 'Done',
    clear_all: 'Clear all',
    empty: 'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.',
    help_and_FAQs: 'Help & FAQs',
    download_application: 'Download our application.',
    find_out_more: 'Find out more',
    more: 'More',
    login: 'Login',
    'forgotten_password?': 'Forgotten password?',
    go_to_checkout: 'Go to checkout',
    go_back_shopping: 'Go back shopping',
    personal_details: 'Personal details',
    edit: 'Edit',
    shipping_details: 'Shipping details',
    client_address: 'Client address',
    client: 'Client',
    shipping_address_per_branch: 'Shipping address (per branch)',
    shipping_address: 'Shipping address',
    end_receiver_shipping_address: 'End recipient delivery addresses',
    standard_end_receiver_shipping_address: 'Default end recipient address',
    all_available_addresses: 'All available addresses',
    end_receiver_modal_header: 'All available end recipient addresses for branch ',
    default_address: 'Default address',
    alternative_address: 'Alternative address',
    same_as_shipping_address: 'Same as shipping address',
    payment_method: 'Payment method',
    apply: 'Apply',
    update_password: 'Update password',
    update_personal_data: 'Update personal data',
    item: 'Item',
    go_back: 'Go back',
    continue_to_shipping: 'Continue to shipping',
    i_agree_to: 'I agree to',
    terms_and_conditions: 'Terms and conditions',
    pay_for_order: 'Pay for order',
    log_into_your_account: 'Log into your account',
    or_fill_the_details_below: 'or fill the details below',
    enjoy_your_free_account: 'Enjoy these perks with your free account!',
    continue_to_payment: 'Continue to payment',
    order_no: 'Order No.',
    successful_placed_order:
        'You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.',
    info_after_order:
        'You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, check history of transactions, edit subscription to newsletter.',
    allow_order_notifications: 'Allow order notifications',
    feedback: 'Your feedback is important to us. Let us know what we could improve.',
    send_my_feedback: 'Send my feedback',
    go_back_to_shop: 'Go back to shop',
    read_all_reviews: 'Read all reviews',
    color: 'Color',
    contact_details_updated: 'Keep your addresses and contact details updated.',
    manage_billing_addresses:
        'Manage all the billing addresses you want (work place, home address...) This way you won"t have to enter the billing address manually with each order.',
    change: 'Change',
    delete: 'Delete',
    delete_confirm: 'Do you really want to delete this item?',
    add_new_address: 'Add new address',
    set_up_newsletter:
        'Set up your newsletter and we will send you information about new products and trends from the sections you selected every week.',
    sections_that_interest_you: 'Sections that interest you',
    save_changes: 'Save changes',
    read_and_understand: 'I have read and understand the',
    privacy: 'Privacy',
    cookies_policy: 'Cookies Policy',
    commercial_information: 'and agree to receive personalized commercial information from Brand name by email',
    feel_free_to_edit: 'Feel free to edit any of your details below so your account is always up to date',
    use_your_personal_data:
        'At Brand name, we attach great importance to privacy issues and are committed to protecting the personal data of our users. Learn more about how we care and use your personal data in the',
    privacy_policy: 'Privacy Policy',
    change_password_your_account:
        'If you want to change the password to access your account, enter the following information',
    your_current_email_address_is: 'Your current email address is',
    product: 'Product',
    details_and_status_orders:
        'Check the details and status of your orders in the online store. You can also cancel your order or request a return.',
    you_currently_have_no_orders: 'You currently have no orders',
    start_shopping: 'Start shopping',
    download: 'Download',
    'download all': 'Download all',
    yiew_details: 'View details',
    manage_shipping_addresses:
        'Manage all the shipping addresses you want (work place, home address...) This way you won"t have to enter the shipping address manually with each order.',
    quantity: 'Quantity',
    price: 'Price',
    back_to_homepage: 'Back to homepage',
    select_shipping_method: 'Select shipping method',
    review_my_order: 'Review my order',
    select_payment_method: 'Select payment method',
    make_an_order: 'Make an order',
    or: 'or',
    login_in_to_your_account: 'login in to your account',
    create_an_account: 'Create an account',
    your_bag_is_empty: 'Your bag is empty',
    cancel: 'Cancel',
    cancel_all: 'Alle Abbrechen',
    see_all_results: 'See all results',
    you_have_not_searched_for_items_yet: 'You haven’t searched for items yet.',
    let_it_start_now_we_will_help_you: 'Let’s start now – we’ll help you.',
    search_results: 'Search results',
    product_suggestions: 'Product suggestions',
    search_for_items: 'Search for items',
    search_for_notes: 'Search for notes',
    c_name: 'C-Name',
    stp_name: 'STP-Name',
    gln: 'GLN',
    c_number: 'C-Number',
    address: 'Address',
    branch: 'Branch',
    'customer Account': 'Customer Account',
    orders: 'Orders',
    shipments: 'Shipments',
    logout: 'Logout',
    name: 'Name',
    created_at: 'Created at',
    number_articles: 'Number articles',
    empty_shopping_list_name: 'Empty shopping list name',
    change_name: 'Change name',
    save_change: 'Save change',
    confirm_delete_wishlist_cart_modal_title: 'Are you sure you want to delete <the shopping cart / the watch list>?',
    confirm_delete_wishlist_cart_modal_message: 'Deleted shopping lists cannot be restored.',
    yes_delete: 'Yes, delete',
    page: 'Page',
    of: 'of',
    go_to: 'Go to',
    apply_2: 'Apply',
    search: 'Search',
    limit_date: 'Limit date',
    from: 'From',
    to: 'To',
    enter_search_term: 'Enter a search term...',
    shopping_cart: 'Shopping cart',
    shopping_carts: 'Shopping carts',
    created: 'Created',
    approved: 'Approved',
    purchase_sum: 'Purchase sum',
    supplier: 'Supplier',
    suppliers: 'Suppliers',
    dealer_margin: 'Dealer margin',
    partial_orders: 'Partial orders',
    intersport_portals: 'Intersport Portals',
    intersport_portal_looged_out: 'Intersport Portal.',
    faq: 'FAQ',
    portal_support: 'Portal Support',
    sales_team: 'Sales Team',
    save: 'Save',
    product_has_been_added_to: 'Product has been added to {shopping-list-name}.',
    back: 'Back',
    what_are_you_looking_for: 'What are you looking for?',
    matrix: 'Matrix',
    total: 'Total',
    create: 'Create',
    delete_all_products: 'Delete all products',
    product_short_model_number: 'M.Nr.',
    ek: 'EK',
    upe: 'UPE',
    net_ek: 'Net-EK',
    Checkout: 'Checkout',
    symbol_quantity: 'Stck.',
    'Total cart': 'Alle Warenkörbe',
    'Create new cart': 'Neuen Warenkorb erstellen',
    article: 'Article',
    active: 'Active',
    description: 'Description',
    update_at: 'Update at',
    ek_sum: 'EK sum',
    upe_sum: 'UPE sum',
    sum: 'Sum',
    total_price: 'total price:',
    last_edited: 'Last edited',
    ok: 'OK',
    sign_in: 'Sign in',
    home_page_title: 'Intersport Vororder',
    home_page_title_no: 'Intersport Nachorder',
    close: 'Close',
    logging_in: 'Logging in...',
    login_title: 'The Preorder Portal is loading',
    login_text: 'One moment please, you are being logged in...',
    login_failed_title: 'Oops, something went wrong',
    login_failed_text:
        'We could not log you into the preorder portal. Please try again or try through the Intersport Portal',
    login_failed_button_portal: 'To the Intersport Portal',
    login_failed_button_retry: 'Try again',
    intersport_portal: 'Intersport Portal',
    auth_failed: 'Authentification failed',
    help_and_service: 'Help & Service',
    home_banner: 'Welcome to our Smartorder Shop',
    home_video_tutorials_headline: 'Video Tutorials',
    marketing_action: 'SALE',
    all_categories: 'All Categories',
    select_placeholder: 'Please choose an option',
    invalid_input: 'Invalid Input!',
    under_construction: 'Under Construction',
    under_construction_text: 'A new pre-order section will be created here soon. Please come back later.',
    error_page_subheadline: 'Sorry, it seems<br> that something went wrong!',
    error_page_text: "We couldn't find the page. Please try again or contact us.",
    to_login: 'To Login',
    try_again: 'Try again',
    to_homepage: 'To Homepage',
    platforms: 'Platforms',
    ci_chips_headline: 'Popular sales systems',
    ci_brands_headline: 'Our top brands',
    help_and_service_route:
        'https://intersport-idea.atlassian.net/servicedesk/customer/portal/3/user/login?destination=portal%2F3',
    ci_important_messages: 'Important messages',
    ci_no_messages: 'There are currently no notifications.',
    welcome: 'Welcome',
    workbook: 'Workbook',
    notes: 'Notes',
    downloads_loading_text: 'The files are being generated. Please do not reload the page during the process.',
    uploads_loading_text: 'The files are being uploaded. Please do not reload the page during the process.',
    downloads_done_text: 'Files were successfully generated.',
    uploads_done_text: 'Files were successfully uploaded.',
    downloads_error_text: 'Some files could not be generated. Please try again.',
    uploads_error_text: 'Some files could not be uploaded. Please try again.',
    reexport_trigger_failed: 'The re-export could not be triggered. Please try again.',
    download_started: 'Download {0} started',
    download_ended: 'Download {0} finished',
    Upload_started: 'Upload {0} started',
    Upload_ended: 'Upload {0} finished',
    generate_print: 'Generate print',
    download_as_pdf: 'Download selected as a complete PDF',
    download_as_zip: 'Download individual PDFs as a .zip file',
    faq_page_title: 'Intersport - FAQ',
    branch_cluster: 'Branch cluster',
    branch_clusters: 'Branch clusters',
    upload_branch_clusters: 'Upload branch clusters',
    branch_clusters_download_template: 'Download template',
    branch_clusters_download_module_template: 'Download module template',
    branch_clusters_upload_warning: "Sorry, that didn't work. We cannot process the CSV file.",
    file: 'File',
    product_group: 'PGR',
    branches: 'Branches',
    date: 'Date',
    branch_clusters_info_tooltip: 'All lines are taken into account, regardless of whether they are filled or not',
    branch_cluster_file_upload_success: 'The file has been uploaded successfully.',
    branch_cluster_file_upload_failure: 'The file could not be uploaded.',
    template_csv: 'Template.csv',
    file_delete_successful: 'Files have been successfully deleted.',
    file_delete_failed: 'Files could not be deleted. Please try again.',
    sms_title: 'SMS',
    sms_title_name: 'Assortment management system',
    video_tutorial_img_alt: 'Video Tutorials',
    pre_order_portal: 'Preorder-Portal',
    reorder_portal: 'Reorder-Portal',
    evaluation: 'Auswertung',
    lagerliste_download_img_alt: 'Download stock list',
}
