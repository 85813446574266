import handleError from '~/composables/api/handleResponse'
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination'

export default async function getAccessToken(useUserLoginParams: object) {
    const url = '/access-tokens'
    const { fetchSdApi } = fetchOrderDetermination()

    const requestData = {
        data: {
            type: 'access-tokens',
            attributes: useUserLoginParams,
        },
    }

    try {
        return await fetchSdApi(url, {
            method: 'POST',
            body: requestData,
        })
    } catch (error) {
        return handleError(error)
    }
}
