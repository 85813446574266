export default {
    create_shopping_list: 'Create shopping list',
    shopping_lists: 'Shopping lists',
    create_new_shopping_list: 'Create new shopping list',
    name_of_shopping_list: 'Name der Merkliste / Warenkorb vorausgefüllt',
    shopping_list: 'Shopping list',
    all_shopping_list: 'All Shopping List',
    new_shopping_list: 'New Shopping List',
    add_to_shopping_list: 'Add to shopping list',
    confirm_add_article_to_shopping_list_message: 'On which shopping list do you want to save the items?',
    to_shopping_list: 'To shopping list',
    delete_shopping_list: 'Delete shopping list',
    shopping_list_details: 'Shopping list details',
    product_bookmark: 'Produkt Merken',
    shopping_list_name: 'Shopping list name',
    shopping_list_general_presentation: 'Shopping list general presentation',
}
