import type { VueCookies } from 'vue-cookies'
import _default from 'vue-cookies'
import type { App } from 'vue'
import type _Vue from 'vue'
import type { StorageInterface } from '~/composables/stores/useCookieStorage'
import { useCookieStorage } from '~/composables/stores/useCookieStorage'

interface CookiesConfig {
    expires: string | number | Date
    path?: string
    domain?: string
    secure?: boolean
    sameSite?: string
}

export declare function install(Vue: typeof _Vue | App, options?: CookiesConfig): void

interface ExtendedVueCookies extends VueCookies {
    install: typeof install
}

export default defineNuxtPlugin((nuxtApp) => {
    const reactiveObj = useCookieStorage()

    // necessary to overwrite original interface, because it's type values are completely wrong
    const newVueCookies = _default as any as ExtendedVueCookies

    const { storage } = storeToRefs(reactiveObj)

    newVueCookies.keys().map((key: string) => {
        reactiveObj.update(newVueCookies.get(key), key)

        return reactiveObj.get(key)
    })

    nuxtApp.provide('cookies', {
        ...newVueCookies,
        set(
            ...args: [
                keyName: string,
                value: any,
                expires?: string | number | Date,
                path?: string,
                domain?: string,
                secure?: boolean,
                sameSite?: string,
            ]
        ) {
            reactiveObj.update(args[1], args[0])

            return newVueCookies.set.apply(newVueCookies, args)
        },
        remove(...args: [keyName: string, path?: string, domain?: string]) {
            reactiveObj.remove(args[0])

            return newVueCookies.remove.apply(newVueCookies, args)
        },
        getRefs(keyName: string): Ref<StorageInterface> | string | null {
            return storage.value[keyName] ?? null
        },
    })
})
