<template>
    <span class="svg-icon" v-html="icon" />
</template>

<script>
import { watchEffect } from 'vue'

export default {
    props: {
        name: {
            type: String,
            required: true,
        },
    },

    setup(props) {
        // refs
        const icon = ref('')

        watchEffect(async () => {
            try {
                const iconsImport = import.meta.glob('/assets/icons/**/**.svg', {
                    eager: false,
                    query: '?raw',
                    import: 'default',
                })

                icon.value = await iconsImport[`/assets/icons/${props.name}.svg`]()
            } catch {
                console.error(`[svg-icon] Icon '${props.name}' doesn't exist in 'assets/icons'`)
            }
        })

        return {
            icon,
        }
    },
}
</script>

<style lang="scss">
.svg-icon {
    display: inline-block;
    vertical-align: middle;

    svg {
        width: 100%;
        height: 100%;
    }
}
</style>
