import type { Emitter, EventType } from 'mitt'
import mitt from 'mitt'

export default defineNuxtPlugin(() => {
    const emitter: Emitter<Record<EventType, any>> = mitt()

    return {
        provide: {
            emitter: {
                $on: emitter.on,
                $off: emitter.off,
                $emit: emitter.emit,
            },
        },
    }
})
