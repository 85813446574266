export default {
    checkout_page_title: 'Address Selection & Approval',
    billing_address: 'Billing address',
    shipping_address: 'Shipping address',
    c_name: 'C-Name',
    gln: 'GLN',
    custom_number: 'Custom number',
    address: 'Address',
    stp_name: 'STP-Name',
    branch_office: 'Branch office',
    quantity: 'Quantity',
    upe: 'Rrp',
    suppliers: 'Suppliers',
    total_short: 'Tot.',
    pieces_short: 'Pcs.',
    total_upe: 'Total RRP',
    total_without_discount: 'Total without discount',
    less_discount: 'Less discount',
    full_total: 'Total',
    release_shopping_cart: 'Release shopping cart',
    thank_you_text: 'Thank you for your order',
    thank_you_secondary: 'Your order has been sent and will be processed as soon as possible by the team XYZ.',
    download_order_details: 'Download order details',
    winter_sport_news: 'Winter Sports News',
    to_collection: 'To Collection',
    winter_basics: 'WINTER SPORTS BASICS',
    innovative_protectors: 'INNOVATIVE PROTECTORS',
    order_overview: 'Order Overview',
    order_overview_details: 'SZ= Size, PC= Piece, TM= Trading Margin',
    product: 'Product',
    variant: 'Variant',
    size_short: 'SZ',
    piece_short: 'PC',
    trading_margin_short: 'TM',
    supplier: 'Supplier',
    discount: 'Discount',
    final_price: 'Final price',
    final_price_info: 'Total incl. applied discounts',
    total_short_info: 'Total excl. discounts',
    applied_discount: 'Applied Discount:',
    supplier_discount: 'Supplier Discount:',
    quantity_discount: 'Quantity Discount:',
    discount_x: 'Discount X:',
    total_discount: 'Total Discount:',
    thank_you_page_title: 'Thank you for your order',
    delivery_date: 'Delivery date',
}
