
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93lQjMG4Z22XMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46category_id_93qx1WcGYlFrMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/c/[...category_id].vue?macro=true";
import { default as _91cart_id_93kaUHJFr9qMMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/cart/[cart_id].vue?macro=true";
import { default as contactts7V9ZkM7IMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/contact.vue?macro=true";
import { default as faqz88SMQVRdoMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/faq.vue?macro=true";
import { default as homegZED008a4UMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/home.vue?macro=true";
import { default as imprintNwqgvJQj0CMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/imprint.vue?macro=true";
import { default as indexystKe4MeanMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/index.vue?macro=true";
import { default as login_45postnVAVuMCYXiMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/login-post.vue?macro=true";
import { default as LoginNnWvJtzOypMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/Login.vue?macro=true";
import { default as LogoutFlC1tMjbqxMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/Logout.vue?macro=true";
import { default as additional_45header_45fieldsQQFtKhObIyMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/additional-header-fields.vue?macro=true";
import { default as addressesM7tc3dx2mzMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/addresses.vue?macro=true";
import { default as branch_45clustersZbyaVjT2E0Meta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/branch-clusters.vue?macro=true";
import { default as cartsUnNCrovLj3Meta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/carts.vue?macro=true";
import { default as condition_45sheetsOwvbLVh0IbMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/condition-sheets.vue?macro=true";
import { default as evaluationtgUVk6wa17Meta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/evaluation.vue?macro=true";
import { default as order_45details_45smove5q7sDjaTMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/order-details-smo.vue?macro=true";
import { default as order_45detailsaL0xmb1DKFMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/order-details.vue?macro=true";
import { default as order_45history_45smocsAuszPWv2Meta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/order-history-smo.vue?macro=true";
import { default as order_45history3uOR2RnSUrMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/order-history.vue?macro=true";
import { default as preferencesblnGkGZR5AMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/preferences.vue?macro=true";
import { default as my_45accountxegBakiv5dMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account.vue?macro=true";
import { default as _91id_93ep6z6tGvmPMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/p/[id].vue?macro=true";
import { default as privacy_45policyyMY0boswOdMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/privacy-policy.vue?macro=true";
import { default as _91_46_46_46slug_932owuBe8b03Meta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/product-notes/[...slug].vue?macro=true";
import { default as smsJd80D0zkVnMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/sms.vue?macro=true";
import { default as _91_46_46_46category_id_93PqK7i7vrIlMeta } from "/codebuild/output/src944252203/src/intersport-order-frontend/pages/workbook/[...category_id].vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93lQjMG4Z22XMeta || {},
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/[...slug].vue")
  },
  {
    name: "c-category_id",
    path: "/c/:category_id(.*)*",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/c/[...category_id].vue")
  },
  {
    name: "cart-cart_id",
    path: "/cart/:cart_id()",
    meta: _91cart_id_93kaUHJFr9qMMeta || {},
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/cart/[cart_id].vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/contact.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/faq.vue")
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/home.vue")
  },
  {
    name: "imprint",
    path: "/imprint",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/imprint.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/index.vue")
  },
  {
    name: "login-post",
    path: "/login-post",
    meta: login_45postnVAVuMCYXiMeta || {},
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/login-post.vue")
  },
  {
    name: "Login",
    path: "/Login",
    meta: LoginNnWvJtzOypMeta || {},
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/Login.vue")
  },
  {
    name: "Logout",
    path: "/Logout",
    meta: LogoutFlC1tMjbqxMeta || {},
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/Logout.vue")
  },
  {
    name: "my-account",
    path: "/my-account",
    meta: my_45accountxegBakiv5dMeta || {},
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account.vue"),
    children: [
  {
    name: "my-account-additional-header-fields",
    path: "additional-header-fields",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/additional-header-fields.vue")
  },
  {
    name: "my-account-addresses",
    path: "addresses",
    meta: addressesM7tc3dx2mzMeta || {},
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/addresses.vue")
  },
  {
    name: "my-account-branch-clusters",
    path: "branch-clusters",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/branch-clusters.vue")
  },
  {
    name: "my-account-carts",
    path: "carts",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/carts.vue")
  },
  {
    name: "my-account-condition-sheets",
    path: "condition-sheets",
    meta: condition_45sheetsOwvbLVh0IbMeta || {},
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/condition-sheets.vue")
  },
  {
    name: "my-account-evaluation",
    path: "evaluation",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/evaluation.vue")
  },
  {
    name: "my-account-order-details-smo",
    path: "order-details-smo",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/order-details-smo.vue")
  },
  {
    name: "my-account-order-details",
    path: "order-details",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/order-details.vue")
  },
  {
    name: "my-account-order-history-smo",
    path: "order-history-smo",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/order-history-smo.vue")
  },
  {
    name: "my-account-order-history",
    path: "order-history",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/order-history.vue")
  },
  {
    name: "my-account-preferences",
    path: "preferences",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/my-account/preferences.vue")
  }
]
  },
  {
    name: "p-id",
    path: "/p/:id()",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/p/[id].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/privacy-policy.vue")
  },
  {
    name: "product-notes-slug",
    path: "/product-notes/:slug(.*)*",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/product-notes/[...slug].vue")
  },
  {
    name: "sms",
    path: "/sms",
    meta: smsJd80D0zkVnMeta || {},
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/sms.vue")
  },
  {
    name: "workbook-category_id",
    path: "/workbook/:category_id(.*)*",
    component: () => import("/codebuild/output/src944252203/src/intersport-order-frontend/pages/workbook/[...category_id].vue")
  }
]