export default {
    narrow_down_your_selection: 'Grenzen Sie Ihre Produktauswahl ein',
    reset_filter: 'Filter zurücksetzen',
    apply_filter: 'Filter bestätigen',
    text: 'Text',
    product_filter: 'Produkte filtern',
    select_criteria_text: 'Wähle Sie die Kriterien aus, nach denen Sie filtern möchten.',
    filter: 'Filter',
    manage_filters: 'Filter verwalten',
    brand_asc: 'Marke aufsteigend',
    brand_desc: 'Marke absteigend',
    name_asc: 'Alphabetisch aufsteigend',
    name_desc: 'Alphabetisch absteigend',
    price_asc: 'Preis aufsteigend',
    price_desc: 'Preis absteigend',
    note_new: 'Neue Notizen zuerst',
    note_old: 'Alte Notizen zuerst',
    storage_ranges: 'Lagerreichweite',
    conditions: 'Konditionen',
    choose_all: 'Alle auswählen',
    favourite_filter_title: 'Beliebte Filter',
    favourite_filter_nos: 'NOS',
    favourite_filter_availability: 'Verfügbar',
    favourite_filter_sale: 'SALE',
}
