import handleError from '~/composables/api/handleResponse'
import type { ApiResponse } from '~/composables/types/api/apiResponse'
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination'

export default async function getCustomerData(): Promise<ApiResponse> {
    const url = '/customers'
    const { fetchSdApi } = fetchOrderDetermination()

    try {
        return await fetchSdApi(url)
    } catch (error) {
        return handleError(error)
    }
}
