export default {
    all_products: 'All Products',
    generate_workbook: 'Generate Workbook',
    download_title: 'Download-Section',
    download_description: 'Download of the exhibition documents',
    filter_placeholder: 'Filter in Workbook',
    search_placeholder: 'Search in Workbook',
    meta: 'Workbook',
    date_title: 'January 2025',
}
