const MAGIC_NUMBERS = {
    TWO: 2,
    BASE36: 36,
}
const base64EncryptFakeCharacters = 6

export const encryptString = (str: string) => {
    const encodedStr = window.btoa(str)
    const randomStr = Math.random()
        .toString(MAGIC_NUMBERS.BASE36)
        .substring(MAGIC_NUMBERS.TWO, MAGIC_NUMBERS.TWO + base64EncryptFakeCharacters)

    return randomStr + encodedStr
}

export const decryptString = (str: string) => window.atob(str.substring(base64EncryptFakeCharacters))
