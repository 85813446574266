<template>
    <button :type="submit ? 'submit' : 'button'" :class="className" :disabled="disabled" :title="title || undefined">
        <slot>
            <span class="button-inner">
                <AtomIcon v-if="icon" :name="icon" :width="iconWidth" :bolding="iconBolding" />
                <AtomBlueInfo v-if="badge.length" class="button-badge" :text="badge" :left-gap="true" />
                <span class="button-text">{{ text }}</span>
            </span>
        </slot>
    </button>
</template>

<script lang="ts" setup>
interface PropType {
    text?: string
    icon?: string
    type?: 'primary' | 'secondary' | 'tertiary' | 'button' | 'submit'
    size?: 'l' | 'm' | 's' | 'xs' | 'reset'
    iconRight?: boolean
    iconWidth?: string | number | null
    iconBolding?: string | number | null
    circular?: boolean
    disabled?: boolean
    badge?: string
    submit?: boolean
    title?: string
    activeLight?: string
}

const props = withDefaults(defineProps<PropType>(), {
    icon: undefined,
    text: '',
    type: 'primary',
    size: 'm',
    badge: '',
    activeLight: '',
    iconRight: false,
    iconBolding: null,
    iconWidth: 16,
    disabled: false,
    title: '',
})

const className = computed(() => {
    let class_names = ''

    if (props.icon && !props.text) {
        class_names = 'icon-only'
    } else if (props.icon && props.text) {
        class_names = 'icon-and-text'
    }
    if (props.text && !props.icon) {
        class_names += ' text-only'
    }
    if (props.iconRight) {
        class_names += ' icon-right'
    }
    if (props.circular) {
        class_names += ' circular'
    }
    if (props.activeLight) {
        class_names += ' active-light'
    }
    class_names += ' button'

    return `${props.type} ${props.size} ${class_names}`
})
</script>

<style lang="scss" scoped>
button {
    padding: 0;
    cursor: pointer;
    border-radius: rem(5);
    box-sizing: border-box;
    text-align: center;
    font-weight: fw(medium);
    white-space: nowrap;

    .button-inner {
        @include helper-flex-centered();

        pointer-events: none;
    }

    &.reset {
        padding: 0;
    }

    &.icon-right .button-inner {
        flex-direction: row-reverse;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &.max-circular {
        @include helper-border-radius(rem(100));
    }

    &.transparent {
        @include helper-color-bg(transparent-background);
    }

    &.primary {
        @include helper-border($setting-color-state-default, rem(1), solid);
        @include helper-color-bg(state-default);
        @include helper-color(white);

        &:disabled {
            @include helper-border($setting-color-state-disabled, rem(1), solid);
            @include helper-color-bg(state-disabled);
        }

        &:hover:not([disabled]) {
            @include helper-border($setting-color-state-hover, rem(1), solid);
            @include helper-color-bg(state-hover);
        }

        &:active:not([disabled]) {
            @include helper-border($setting-color-state-press, rem(1), solid);
            @include helper-color-bg(state-press);
        }
    }

    &.secondary {
        @include helper-border($setting-color-state-default, rem(1), solid);
        @include helper-color-bg(white);
        @include helper-color(state-default);

        &:disabled {
            @include helper-border($setting-color-state-disabled, rem(1), solid);
            @include helper-color(state-disabled);
        }

        &:hover:not([disabled]) {
            box-shadow: 0 0 0 rem(1) $setting-color-state-default;
        }

        &:active:not([disabled]) {
            @include helper-color-bg(state-disabled);

            box-shadow: 0 0 0 rem(1) $setting-color-state-press;
        }
    }

    &.tertiary {
        border: none;
        background-color: transparent;
        text-decoration-color: $setting-color-state-default;

        @include helper-color(state-default);

        &:not(.header__action) {
            text-decoration: underline;
        }

        &:disabled {
            @include helper-color(state-disabled);
        }

        &:active:not([disabled]) {
            text-decoration: none;
        }
    }

    &.active-light {
        @include helper-color-bg(state-disabled);
    }

    &.text-only {
        padding: 0 sp(xs);
    }

    &.icon-and-text {
        padding: 0 sp(s);
    }

    &.l {
        height: rem(48);
        font-size: fs(default);

        &:not(.matrix-modal__nav--prev, .matrix-modal__nav--next) {
            min-width: rem(48);
        }

        &.icon-only {
            &.circular {
                @include helper-border-radius-circle();
            }
        }

        &.icon-and-text .button-text {
            margin-left: sp(xs);
        }

        &.icon-right.icon-and-text .button-text {
            margin-left: 0;
            margin-right: sp(xs);
        }

        &.icon-right.icon-and-text .button-badge {
            margin-left: 0;
            margin-right: sp(xs);
        }

        @include helper-svg-size(24);
    }

    &.m {
        min-width: rem(40);
        height: rem(40);
        font-size: fs(small);

        &.icon-only {
            &.circular {
                @include helper-border-radius-circle();
            }
        }

        &.icon-and-text {
            .svg-icon {
                margin-left: rem(-1);
                margin-right: sp(xs);
            }
        }

        &.icon-right.icon-and-text .svg-icon {
            margin-left: sp(xs);
            margin-right: rem(-1);
        }

        @include helper-svg-size(20);
    }

    &.s {
        min-width: rem(32);
        height: rem(32);
        font-size: rem(10);

        &.icon-only {
            &.circular {
                @include helper-border-radius-circle();
            }
        }

        &.icon-and-text span {
            margin-right: rem(5);
            margin-left: rem(-3);
        }

        &.icon-right.icon-and-text span {
            margin-left: rem(5);
            margin-right: rem(-3);
        }

        @include helper-svg-size(16);
    }
}
</style>
