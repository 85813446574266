export enum LocalStorageNameTypes {
    ORDER_FIELDS_KEY = 'order-fields',
    PLP_SORT_KEY = 'plp-sortby',
    PRODUCT_SAVED_DELIVERY_DATE = 'product_saved_delivery_date',
    // eslint-disable-next-line no-unused-vars
    CUSTOMER = 'customer',
    USER_ACTIVE_CART = 'cc-active-cart',
    CLOSED_BRANCHES_STORAGE = 'matrix-closed-branches',
    LAST_SELECTED_VIEW_TYPE = 'last_selected_view_type',
    LAST_SELECTED_MATRIX_GROUP_BY = 'last_selected_matrix_group_by',
    LAST_SELECTED_QUICK_SELECTION = 'last_selected_quick_selection',
    ADDITIONAL_ORDER_FIELDS_ACCORDION_SELECTION = 'additional_order_fields_accordion_selection',
    SHOW_EK_PRICES = 'show-ek-prices',
}
