import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src944252203/src/intersport-order-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src944252203/src/intersport-order-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src944252203/src/intersport-order-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src944252203/src/intersport-order-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/codebuild/output/src944252203/src/intersport-order-frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src944252203/src/intersport-order-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src944252203/src/intersport-order-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/codebuild/output/src944252203/src/intersport-order-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src944252203/src/intersport-order-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src944252203/src/intersport-order-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_I4dbrL2rYz from "/codebuild/output/src944252203/src/intersport-order-frontend/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import cookies_rCS2psPm8j from "/codebuild/output/src944252203/src/intersport-order-frontend/plugins/cookies.ts";
import emit_F0aYdofLSo from "/codebuild/output/src944252203/src/intersport-order-frontend/plugins/emit.ts";
import googleTagManager_QmmW6lPxbX from "/codebuild/output/src944252203/src/intersport-order-frontend/plugins/googleTagManager.ts";
import i18n_VfGcjrvSkj from "/codebuild/output/src944252203/src/intersport-order-frontend/plugins/i18n.ts";
import plyr_m1vubbxwTm from "/codebuild/output/src944252203/src/intersport-order-frontend/plugins/plyr.ts";
import roles_HRwQbKr63P from "/codebuild/output/src944252203/src/intersport-order-frontend/plugins/roles.ts";
import sanitizeHtml_hIRTWS2A50 from "/codebuild/output/src944252203/src/intersport-order-frontend/plugins/sanitizeHtml.ts";
import store_9xNuDHGAVU from "/codebuild/output/src944252203/src/intersport-order-frontend/plugins/store.ts";
import userFlow_V7Uhpxj2eH from "/codebuild/output/src944252203/src/intersport-order-frontend/plugins/userFlow.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_I4dbrL2rYz,
  cookies_rCS2psPm8j,
  emit_F0aYdofLSo,
  googleTagManager_QmmW6lPxbX,
  i18n_VfGcjrvSkj,
  plyr_m1vubbxwTm,
  roles_HRwQbKr63P,
  sanitizeHtml_hIRTWS2A50,
  store_9xNuDHGAVU,
  userFlow_V7Uhpxj2eH
]