export default {
    demo_page: 'PDP',
    show_info_for: 'Informationen anzeigen für',
    description: 'Beschreibung',
    prod_details: 'Produktdetails',
    also_at: 'Auch bei',
    related_color_lots: 'Weitere Farblots',
    select_color: 'Produkt Farbe wählen',
    select_size: 'Größen',
    lot: 'lot',
    display: 'display',
    product_package: 'Product Package',
    model_in_cart_headline: 'Modell ist in folgenden Warenkörben vorhanden',
}
