<template>
    <SvgIcon v-if="name" :name="name" />
</template>

<script lang="ts" setup>
const props = withDefaults(
    defineProps<{
        name: string
        width?: number | string | null
        bolding?: number | string | null
    }>(),
    {
        name: '',
        width: 16,
        bolding: null,
    }
)

const width = computed(() => `${props.width}px`)
const bolding = computed(() => {
    if (props.bolding) {
        return `${props.bolding}`
    }

    return ''
})
</script>

<style lang="scss">
.svg-icon {
    width: v-bind(width);

    svg {
        stroke-width: v-bind(bolding);
    }
}
</style>
