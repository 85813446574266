import type {
    ApiErrorResponse,
    ApiGenericErrorResponse,
    ApiSuccessResponse,
    ErrorResponseObject,
    ErrorResponseString,
} from '~/composables/types/api/apiResponse'
import { ResponseStatusTypes } from '~/composables/types/responseStatusTypes'

export default function handleError<ErrorDataType>(
    response: ApiGenericErrorResponse<ErrorDataType> | any
): ApiErrorResponse {
    if (typeof response === 'object') {
        if (response?.status) {
            response = response as ApiGenericErrorResponse<ErrorResponseObject<ErrorDataType>>

            if (response?._data?.errors) {
                return {
                    state: 'failed',
                    error: response._data?.errors?.[0] ?? JSON.stringify(response),
                    status: response.status,
                }
            }
        }

        response = response as ApiGenericErrorResponse<ErrorResponseString<ErrorDataType>>
    }

    return {
        state: 'failed',
        error: response?.error ? response.error : JSON.stringify(response),
        status: response?.status ? response.status : ResponseStatusTypes.FALLBACK,
    }
}

export function handleSuccess<SuccessDataType>(response: SuccessDataType): ApiSuccessResponse<SuccessDataType> {
    return {
        ...response,
        error: false,
        state: 'success',
    }
}
