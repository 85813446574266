export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig()

    return {
        provide: {
            get store(): string {
                return config.public.platformType
            },
            isStore(type: StoreTypes.VO | StoreTypes.NO): boolean {
                return type === config.public.platformType
            },
        },
    }
})
