<template>
    <NuxtLayout :name="layout">
        <div class="error-page">
            <MoleculeErrorPageContent :status-code="props.error.statusCode" :show-buttons="showButtons" />
        </div>
    </NuxtLayout>
</template>

<script lang="ts" setup>
import type { LayoutKey } from '#build/types/layouts'

const props = defineProps<{
    error: {
        statusCode: number
    }
}>()

const showButtons = true
const { isAuthenticated } = useUser()
const layout = ref<false | LayoutKey>('default')

useHead({
    title: props.error.statusCode.toString(),
})

if (props.error.statusCode === ResponseStatusTypes.FORBIDDEN || !isAuthenticated.value) {
    layout.value = 'error'
}
</script>

<style lang="scss" scoped>
.error-page {
    margin: sp(xxl) 0;
}
</style>
