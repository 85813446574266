export enum NuxtLinkNameTypes {
    // eslint-disable-next-line no-unused-vars
    HOME = '/',

    LOGIN_POST = '/login-post',
    ACCOUNT_PAGE = '/my-account',
    BRANCH_CLUSTERS = '/my-account/branch-clusters',
    CARTS_PAGE = '/my-account/carts',
    CART_PAGE = '/cart',
    // eslint-disable-next-line no-unused-vars
    CATEGORY = '/c',
    // eslint-disable-next-line no-unused-vars
    PRODUCT = '/p',
    CATALOG_SEARCH = '/c/catalog-search',
    SALES_SEARCH = '/c/catalog-search?has_discount=true',
    ORDER_DETAILS_PAGE = '/my-account/order-details',
    ORDER_DETAILS_PAGE_SMO = '/my-account/order-details-smo',
    ORDER_HISTORY_PAGE = '/my-account/order-history',
    ORDER_HISTORY_PAGE_SMO = '/my-account/order-history-smo',
    ADDRESSES_PAGE = '/my-account/addresses',
    CONDITION_SHEETS_PAGE = '/my-account/condition-sheets',
    WORKBOOK_PAGE = '/workbook',
    NOTES_PAGE = '/product-notes',
    // eslint-disable-next-line no-unused-vars
    SMS = '/sms',
    FAVOURITE_LINK_NOS = '/c/catalog-search?page=1&label[]=NOS&label[]=SAISON',
    FAVOURITE_LINK_AVAILABLE = 'c/catalog-search?page=1&is_available=true',
    FAVOURITE_LINK_SALE = 'c/catalog-search?page=1&has_discount=true',
}
